import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AuthService } from '@services/auth-services/auth.service';
import { Database, onValue, ref, update } from '@angular/fire/database';

const KEY = 'user_settings';

/**
 * A service for managing user-specific settings, providing methods to observe, retrieve, and update settings data.
 * This service communicates with the backend to persist settings for individual users.
 */
@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  private settings = new BehaviorSubject<Map<string, any>>(new Map<string, any>());

  constructor(
    private authService: AuthService,
    private database: Database
  ) {}

  /**
   * Retrieves the current settings as an observable.
   *
   * @return {Observable<any>} An observable that emits the current settings.
   */
  getSettings(): Observable<any> {
    return this.settings.asObservable();
  }

  /**
   * Retrieves a snapshot of the current settings.
   *
   * @return {Object} The current settings value.
   */
  getSettingsSnapshot(): any {
    return this.settings.getValue();
  }

  /**
   * Retrieves the settings entry associated with the specified key.
   *
   * @param {string} key - The key used to identify the desired settings entry.
   * @return {Observable<any>} An Observable that emits the value of the settings entry corresponding to the specified key.
   */
  getSettingsEntry(key: string): Observable<any> {
    return this.getSettings().pipe(
      map(settingsMap => {
        return settingsMap?.get(key);
      })
    );
  }

  /**
   * Saves a key-value pair into the current settings and updates the settings state.
   *
   * @param {string} key The key for the settings entry to be saved.
   * @param {any} value The value associated with the key to be saved.
   * @return {void} This method does not return a value.
   */
  saveSettingsEntry(key: string, value: any): void {
    const settings = this.getSettingsSnapshot();
    settings.set(key, value);
    const entries = Object.fromEntries(settings);
    this.set(entries);
  }

  /**
   * Creates an Observable that listens for changes in the user's settings data stored in the database.
   * Updates the internal settings with the latest data upon receiving updates.
   *
   * @return {Observable<any>} An Observable that emits the user's settings data retrieved from the database as it changes.
   */
  watch(): Observable<any> {
    const userId = this.getCurrentUserId();
    const path: string = `${KEY}/${userId}`;
    return new Observable(observer => {
      const dataRef = ref(this.database, path);
      return onValue(
        dataRef,
        snapshot => {
          observer.next(snapshot.val());
        },
        error => {
          observer.error(error);
        }
      );
    }).pipe(
      map(settings => {
        const settingsMap = new Map<string, any>(Object.entries(settings || {}));
        this.settings.next(settingsMap);
        return settings;
      })
    );
  }

  /**
   * Create or update user settings
   * @param settings
   */
  async set(settings: any): Promise<void> {
    const userId = this.getCurrentUserId();
    const path = `${KEY}/${userId}`;
    const dataRef = ref(this.database, path);
    await update(dataRef, settings);
  }

  /**
   * Gets the currently logged user
   * @private
   */
  private getCurrentUserId(): string | undefined {
    const user = this.authService.getCurrentUser();
    return user?.uid || user?.id;
  }
}
